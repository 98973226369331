import { PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React, { useState, useEffect } from 'react'
import { AssetType } from '../assignment/[assignment_id]'
const ReactQuill = typeof window !== 'undefined' ? require('react-quill') : () => <></>

import 'quill/dist/quill.snow.css'
import 'styles/index.scss'

const Module: React.FC<PageProps> = ({ params: { id: course, module_id } }) => {
	const { ShowDetails, viewAs } = useGetCourseDetails(course)
	const [asset, setAsset] = useState<AssetType>()
	useEffect(() => {
		const run = async () => {
			const asset = await getModule({ module_id, viewAs })

			if (asset) {
				setAsset(asset)
			}
		}
		run()
	}, [module_id, viewAs])
	return (
		<>
			{ShowDetails}
			{asset && (
				<>
					<h2 className='pt-2'>{asset.name}</h2>
					<ReactQuill
						theme='snow'
						value={asset.description}
						readOnly={true}
						modules={{
							toolbar: false,
						}}
					/>
				</>
			)}
		</>
	)
}

export default Module

async function getModule({}: { module_id: string; viewAs: string }): Promise<AssetType> {
	throw new Error('Function not implemented.')
}
